var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "p-relative", attrs: { tile: "" } },
        [
          _c("v-img", {
            attrs: {
              src: _vm.baseImgUrl + _vm.data.data.mobile.app_image_main,
            },
          }),
          _c("div", {
            staticClass: "download-btn cursor-pointer",
            on: { click: _vm.downloadApp },
          }),
          _c("input", {
            attrs: { id: "recommendCode", type: "text" },
            domProps: { value: _vm.recommendCode },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }