<template>
  <div>
    <v-card
      class="p-relative"
      tile
    >
      <v-img :src="baseImgUrl + data.data.mobile.app_image_main" />
      <div
        class="download-btn cursor-pointer"
        @click="downloadApp"
      />
      <input
        id="recommendCode"
        type="text"
        :value="recommendCode"
      >
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { deviceCheck } from '../../../../utils/device'
import { copyNumber } from '../../../../utils/copy'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['baseImgUrl']),
    ...mapState({
      downloadAppBgLogo: state => state.websiteData.mobile.app_image_main,
      recommendCode: state => state.user.userData.recommend_code,
    }),
  },

  methods: {
    ...mapActions(['get_app_download_url', 'set_website', 'show_alert']),

    /**
 * 復製文字
 * @date 2021-08-31
 * @param {string} id 需要復製文字的 input id
 */
    copyNumber(id) {
      copyNumber(id)
    },

    goDownloadPage(downloadUrl) {
      if (!downloadUrl) {
        this.show_alert({
          icon: 'fail',
        })
        return false
      }

      this.copyNumber('recommendCode')
      location.href = downloadUrl
    },
    /**
   * 下載 app && 復製 recommend
   */
    async downloadApp() {
      const device = deviceCheck()
      const website = await this.set_website()

      const { data: { android_landscape_update_url, app_landscape_download_url } } = await this.get_app_download_url({ website })

      device === 'android' ? this.goDownloadPage(android_landscape_update_url) : this.goDownloadPage(app_landscape_download_url)
    },
  },
}
</script>

<style lang="scss" scoped>
.download-btn {
  position: absolute;
  top: 0;
  right:0;
  height: 100%;
  width: 50%;
}

#recommendCode {
  height: 0;
}
</style>
